(function () {
  if (window.active_modules.indexOf("resize") === -1) {
    return;
  }

  function iframesInDocument() {
    return document.querySelectorAll("iframe");
  }
  function resourceContainers() {
    return document.querySelectorAll(".resource-container");
  }

  const resourceContainerWrapper = document.querySelector(".resource-container-wrapper");
  const iframeAuto = resourceContainerWrapper.classList.contains("ratio-auto");

  const rcIframes = Array.prototype.map.call(resourceContainers(), (rc) => {
    return rc.querySelector("iframe");
  }).filter((i) => {
    return !!i;
  });

  function iframes() {
    return Array.prototype.filter.call(iframesInDocument(), (iframe) => {
      for (let i = 0; i < rcIframes.length; i++) {
        if (iframe.isEqualNode(rcIframes[i])) {
          return false;
        }
      }

      return true;
    });
  }

  function resize() {
    const heightInPx = `${window.innerHeight}px`;
    Array.prototype.forEach.call(iframes(), (iframe) => {
      iframe.style.minHeight = heightInPx;
    });
    Array.prototype.forEach.call(resourceContainers(), (rc) => {
      rc.style.minHeight = heightInPx;

      if (!iframeAuto && rc.children.length === 1 && rc.children[0].tagName === "IFRAME") {
        rc.children[0].style.height = heightInPx;
      }
    });
  }

  function resizeIfragasatt(message) {
    if (message.origin === "https://direkt-klient.ifragasatt.se") {
      if (message.data && message.data.id && message.data.height) {
        const item = document.getElementById(message.data.id);
        if (!item) {
          return;
        }

        const height = message.data.height;
        const iframe = item.querySelector("iframe");
        iframe.classList.add("iframe--ifragasatt__resized");
        item.style.height = `${height}px`;
        item.style.overflow = "hidden";
      }
    }
  }

  function resizeInfogram(message) {
    let data;
    try {
      data = JSON.parse(message.data);
    } catch {
      return false;
    }

    if (data.context !== "iframe.resize") {
      return false;
    }

    const iframe = document.querySelector(`iframe[src="${data.src}"]`);

    if (!iframe) {
      return false;
    }

    iframe.height = data.height;

    return;
  }

  function resizeFamilj(message) {
    if (message.data.includes("FAMILJ_HEIGHT")) {
      const iframe = document.querySelector("iframe");
      if (!iframe) {
        return;
      }

      const height = message.data.slice(14);
      iframe.style.height = `${height}px`;
      iframe.style.overflow = "hidden";
    }
  }

  function resizePatron(message) {
    if (message.data && message.data.location && message.data.ratio) {
      const iframeLocation = message.data.location;
      const iframe = document.querySelector(`iframe[src="${iframeLocation}"`);
      const height = `${iframe.clientWidth * message.data.ratio}px`;
      iframe.height = height;
    }
  }

  if (resourceContainerWrapper.classList.contains("resource-ifragasatt")) {
    window.addEventListener("message", resizeIfragasatt, false);
  } else if (resourceContainerWrapper.classList.contains("resource-infogram")) {
    window.addEventListener("message", resizeInfogram, false);
  } else if (resourceContainerWrapper.classList.contains("resource-patron")) {
    window.addEventListener("message", resizePatron, false);
  } else if (
    resourceContainerWrapper.classList.contains("resource-tillminne") ||
    resourceContainerWrapper.classList.contains("resource-hm_familjeannons") ||
    resourceContainerWrapper.classList.contains("resource-familj")
  ) {
    window.addEventListener("message", resizeFamilj, false);
  } else {
    resize();
    setInterval(resize, 500);
  }

})();
